<template>
  <div class="message">
    <watch-head :title="$t('消息/聊天')"
                :img="require('@/assets/bracelet_img/67.png')" />
    <div class="main">
      <div class="name">{{HistoricalUser[1].nickname}}</div>
      <!-- <div class="input">输入当中...</div> -->
      <div class="input"></div>
      <van-pull-refresh class="msg"
                        ref="pullRefs"
                        v-model="refreshing"
                        @refresh="onRefresh">
        <div class="row"
             v-for="(item, index) in list"
             :key="index">
          <div v-if="item.from_uid == Historical.id"
               class="left">
            <div class="avatar">
              <img class="img"
                   :src="HistoricalUser[1].avatar"
                   alt="">
              <div class="grade">{{HistoricalUser[1].level_no}}</div>
            </div>
            <div class="text">{{item.text}}</div>
          </div>
          <div v-else
               class="right">
            <div class="text">{{item.text}}</div>
            <div class="avatar">
              <img class="img"
                   :src="HistoricalUser[0].avatar"
                   alt="">
              <div class="grade">{{HistoricalUser[0].level_no}}</div>
            </div>
          </div>
        </div>

      </van-pull-refresh>
      <div class="send">
        <van-field class="sendOut"
                   v-model="message"
                   label-width="0"
                   :placeholder="$t('在此输入...')">
          <template #right-icon>
            <img class="xia"
                 @click="sendOut()"
                 src="@/assets/bracelet_img/68.png"
                 alt="">
          </template>
        </van-field>
      </div>
    </div>

    <chat-foot />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import chatFoot from '@/views/chat/components/chatFoot.vue'
import { connectWebsocket, sendMessage, closeWebsocket } from '@/utils/websocket.js'
import { HistoricalDataApi, sendMsgApi } from '@/api/api'

export default {
  components: {
    watchHead,
    chatFoot
  },
  data () {
    return {
      message: '',
      refreshing: false,
      list: [],
      userInfo: {},
      Historical: {
        page: 1,
        limit: 20,
        id: '',
      },
      isRefreshing: false, // 是否还有内容
      HistoricalUser: [
        { nickname: '' },
        { nickname: '' },
      ],
      WebSocketLoad: null,
      isMsg: false
    }
  },
  created () {
    this.waitForGlobal()
  },
  beforeDestroy () {
    closeWebsocket() //关闭Websocket
  },
  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.Historical.id = this.$route.query.fromUid
        this.getUserInfo()
        

      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 获取当前用户信息 */
    getUserInfo () {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.binding()
    },
    /* 初始化web socket 并绑定 */
    binding () {
      let data = {
        type: "bind",
        uid: this.userInfo.id,
      }
      this.WebSocketLoad = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('连接中...'),
      });
      connectWebsocket(data,
        (res) => {
          // console.log(res)
          if (res.code == 0) {
            if (res.data.code == 99999) {
              this.list.push(res.data.data)
              let timer = setTimeout(() => {
                clearTimeout(timer)
                this.$refs.pullRefs.$el.scrollTop = 999999
              }, 100)
            }else{
               this.Historical.page = 1
               this.isRefreshing = false
               this.list = []
               this.onRefresh()
            }

          }
          this.WebSocketLoad.clear();
        },
        () => {
          // this.WebSocketLoad = this.$toast.loading({
          //   duration: 0, // 持续展示 toast
          //   forbidClick: true,
          //   message: '连接中...',
          // });
          this.WebSocketLoad.clear();
          console.log("失败的回调函数")
        })
    },

    /* 上拉加载数据 */
    onRefresh () {
      let that = this
      if (that.isRefreshing) {
        that.$toast(that.$t('没有更多了'))
        that.refreshing = false
        return
      }

      HistoricalDataApi(that.Historical).then(res => {
        if (res.code == 0) {
          that.isRefreshing = res.data.data.length < that.Historical.limit
          that.list = [].concat(res.data.data, that.list)
          that.HistoricalUser = res.data.userList


          if (that.Historical.page == 1) {
            let timer = setTimeout(() => {
              clearTimeout(timer)
              that.$refs.pullRefs.$el.scrollTop = 999999
            }, 100)
          } else {

            let timer = setTimeout(() => {
              clearTimeout(timer)
              that.$refs.pullRefs.$el.scrollTop = (-(this.$refs.pullRefs.$el.scrollHeight - (this.$refs.pullRefs.$el.scrollHeight + this.$refs.pullRefs.$el.scrollTop + this.$refs.pullRefs.$el.clientHeight))) + this.$refs.pullRefs.$el.clientHeight

            }, 100)
          }

          that.Historical.page++
          that.refreshing = false
        }

      })
    },
    /* 发送消息 */
    sendOut () {
      let that = this
      let data = {
        msg: that.message,
        to_id: that.Historical.id,
        id: that.userInfo.id,
      }

      //  if (type == 'no') {
      //    data.msg = '123'
      //  }
      if (that.message.length == 0) {
        that.$toast(that.$t('发送消息不能为空'))
        return
      }
      if(this.isMsg){
        return
      }
      this.isMsg = true
      sendMsgApi(data).then(res => {
        if (res.code == 0) {
          that.list.push(res.data)
          let timer = setTimeout(() => {
            clearTimeout(timer)
            that.$refs.pullRefs.$el.scrollTop = 999999
          }, 100)
          if (res.data.from_uid == that.userInfo.id) {
            that.message = ''
          }
        }
        this.isMsg = false
      })

      //  sendMessage(data, (res) => {
      //    console.log(res)
      //    if (res.code == 0) {
      //      that.list.push(res.data)
      //      let timer = setTimeout(() => {
      //        clearTimeout(timer)
      //        that.$refs.pullRefs.$el.scrollTop = 999999
      //      }, 100)
      //      if (res.data.from_uid == that.userInfo.id) {
      //        that.message = ''
      //      }
      //    }

      //  }, (err) => {
      //    console.log(err)
      //  })
    }

  }
}
</script>

<style lang="less" scoped>
.message {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 80px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 30px;
    padding: 0 33px;
    .name {
      font-family: 'AeroMaticsBold';
      font-size: 29px;
    }
    .input {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin: -2px 0 4px;
    }
    .msg {
      width: 100%;
      height: 457px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      padding: 12px;
      overflow-y: scroll;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .row {
        margin-bottom: 10px;
        .left {
          display: flex;
          align-items: center;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .text {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 8px 14px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            margin-left: 8px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .avatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: #2b2b2b;
            border: 3px solid #2b2b2b;
            border-radius: 50%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .grade {
              position: absolute;
              right: -10px;
              bottom: -2px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #2b2b2b;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .text {
            background-color: #ffffff;
            border-radius: 15px;
            padding: 8px 14px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            margin-right: 8px;
          }
        }
      }
    }
    .msg::-webkit-scrollbar {
      display: none;
    }
    .send {
      margin-top: 2px;
      .van-field {
        background-color: #ffffff;
        border-radius: 8px;
        // padding: 2px 7px;
        /deep/.van-field__right-icon {
          display: flex;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
        /deep/.xia {
          width: 20px;
          height: 20px;
        }
      }
      .sendOut {
        /deep/.van-field__control {
          font-family: 'TsangerYuYangT-W03';
          font-size: 15px;
          color: #2b2b2b;
        }
        /* WebKit, Blink, Edge */
        /deep/.van-field__control::-webkit-input-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 4 to 18 */
        /deep/.van-field__control:-moz-placeholder {
          font-size: 15px;
        }
        /* Mozilla Firefox 19+ */
        /deep/.van-field__control::-moz-placeholder {
          font-size: 15px;
        }
        /* Internet Explorer 10-11 */
        /deep/.van-field__control:-ms-input-placeholder {
          font-size: 15px;
        }
        /* Microsoft Edge */
        /deep/.van-field__control::-ms-input-placeholder {
          font-size: 15px;
        }
      }
    }
  }
}
</style>